import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { theme } from '../../../domain/theme';
import { Button } from '../../responsive/atoms/Button';
import { ParagraphWrapper } from '../ParagraphWrapper';

const styles = {
  ctaButton: css`
    color: #000;
    cursor: pointer;
    background-color: #fc0;
    ${theme.font.family('boldWeb')};
    font-size: ${theme.font.size('xs')};
    line-height: 1.8;
    letter-spacing: 3px;
    margin: ${theme.spacing.parse('$xs2 auto')};
    text-align: center;
    text-transform: uppercase;

    &:hover {
      background: #000;
      color: #fff;
      a {
        color: #fff;
      }
    }
    a {
      color: #000;
    }
  `
};

const CallToActionButtonInner = ({ data, center }) => {
  const { ctaButtonLink, ctaButtonText, ctaOpenInNewTab } = data;
  const url = ctaButtonLink.url?.path;
  const target = ctaOpenInNewTab === true ? '_blank' : '_self';
  styles.buttonWrapper = css`
    display: ${center ? 'flex' : 'inline-block'};
    clear: both;
  `;
  return (
    <div css={styles.buttonWrapper}>
      <Button
        color="primary"
        padding="xs"
        css={styles.ctaButton}
        to={url}
        target={target}
      >
        {ctaButtonText}
      </Button>
    </div>
  );
};
CallToActionButtonInner.defaultProps = {
  center: true
};
CallToActionButtonInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  center: PropTypes.bool
};

export const CallToActionButton = ParagraphWrapper(CallToActionButtonInner);
